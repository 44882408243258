import React, {
  useState,
  useContext,
  useEffect,
  memo,
  useCallback,
} from "react";
import Search from "./Search";
import { useNavigate } from "react-router-dom";
import { BsSearch, BsChevronLeft } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import ProductPreviewContext from "../../../context/ProductPreviewContext";
import { TypeAnimation } from "react-type-animation";
import ResponsiveContext from "../../../context/ResponsiveContext";
import { getFilterJsonForProductSearch } from "../../../functions/searchAlgos/searchAlogHelperFunctions";
let timeOutId;
function SearchBar({ isSearch, handleSearchClose }) {
  const navigate = useNavigate();

  // -------------- ** contexts ** ------------------
  const { getBySearchText } = useContext(ProductPreviewContext);
  const { screenWidth } = useContext(ResponsiveContext);

  // ------------------- ** states ** --------------------
  const debounce = (func, delay = 900) => {
    return function (...args) {
      clearTimeout(timeOutId);
      timeOutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedCall = debounce((srData) => fetchBySearchText(srData), 250);
  const [productArr, setProductArr] = useState([]);
  const [srData, setSrData] = useState(""); // search bar data
  const [search, setSearch] = useState(false); // in order to show the drop down menu for category list
  const handleCloseSearchContainer = useCallback(() => {
    setSrData("");
    setSearch(false);
    isSearch && handleSearchClose();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSearch) setSearch(true);
  }, [isSearch]);

  useEffect(() => {
    if (srData.length >= 3) {
      debouncedCall(srData);
    }
    // eslint-disable-next-line
  }, [srData, getBySearchText]);

  const fetchBySearchText = async (srData) => {
    const res = await getBySearchText(srData);
    setProductArr(res.slice(0, 5));
  };
  const handleSearch = async (e) => {
    setSrData(e.target.value);
  };

  // when user click the search btn
  const handleSearchBtn = async (e) => {
    e.preventDefault();

    let searchText = srData?.toLowerCase();

    if (searchText.startsWith("/")) {
      const idsText = searchText.split("/")[1];
      const ids = idsText.split(",")?.map((id) => id.trim());

      const encodedURI = encodeURIComponent(`{"ids":${JSON.stringify(ids)}}`);

      navigate("/decors/predefined?filter=" + encodedURI);

      return;
    }

    const filter = getFilterJsonForProductSearch({
      searchText: srData,
      label: "Results for '" + srData + "'",
    });
    navigate(`/decors/list?filter=` + filter);
    handleCloseSearchContainer();
    setTimeout(() => {
      setSrData("");
    }, 100);
  };

  return (
    <div
      style={
        search && screenWidth <= 768
          ? { position: "fixed" }
          : { position: "sticky" }
      }
      className="bg-[white] transition-all  md:col-span-1 md:row-start-auto
       top-0 row-start-3  mt-2 md:mt-0 col-span-4 flex items-center justify-center sticky z-[20] w-[100%] pt-2 pb-4  "
    >
      <form
        onSubmit={handleSearchBtn}
        className="flex justify-between items-center rounded-md border border-[#d9d9d9] w-[95%] md:w-[80%] z-[2]  relative h-[2.8rem] bg-[white] pl-1"
      >
        <div
          onClick={handleCloseSearchContainer}
          className="flex h-[100%] text-[#13131388] z-[3] items-center px-2 text-[1.2rem] justify-center"
        >
          {search ? (
            <BsChevronLeft />
          ) : (
            <BsSearch className=" text-[#52505088]  " />
          )}
        </div>
        <input
          type="text"
          id="ebo-search-bar"
          onClick={() => {
            setSearch(true);
          }}
          onChange={handleSearch}
          value={srData}
          name="search"
          autoComplete="off"
          className=" w-[100%] absolute pl-[2.2rem]  z-[2] bg-[#ffffff00] rounded-md placeholder:text-[#52505088]  h-[100%] flex focus:outline-none text-[.825rem] md:text-[1rem] "
        />
        {srData.length <= 0 && (
          <TypeAnimation
            className="absolute z-[1] text-[#52505088] left-[2.5rem] "
            sequence={[
              `Search for "Birthday"`,
              2000,
              `Search for "BYOD"`,
              2000,
              `Search for "Welcome"`,
              2000,
              `Search for "Get Together"`,
              2000,
              `Search for "Pet Pawrty"`,
              2000,
              `Search for "Naming Ceremony"`,
              2000,
              `Search for "Kid's Party"`,
              2000,
              `Search for "Baby Shower"`,
              2000,
            ]}
            style={{ fontSize: "1rem" }}
            repeat={Infinity}
          />
        )}
        <button type="submit" className="hidden">
          search
        </button>
        {search && srData && (
          <RxCrossCircled
            onClick={() => {
              setSrData("");
            }}
            className="text-[1.3rem] cursor-pointer absolute right-2 z-[3]  text-[#17171775] "
          />
        )}
      </form>

      {search ? (
        <Search
          productArr={productArr}
          setSrData={setSrData}
          srData={srData}
          setSearch={setSearch}
          handleCloseSearchContainer={handleCloseSearchContainer}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default memo(SearchBar);
