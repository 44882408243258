import React, {
  useMemo,
  useState,
  memo,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import { v2Api } from "../../api/v2.api";
import { GoMute, GoUnmute } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import { useGlobalStore } from "../../context/GlobalStoreContext";
import { useCartContext } from "../../context/CartContext";
import { motion, AnimatePresence } from "framer-motion";
import { AiOutlineExpandAlt } from "react-icons/ai";
import { useHomePageContext } from "../../context/HomepageContext";

const VIDEO_ASPECT_RATIO = "9/16";
const COVER_VIDEO_WIDHT = 120;

let getVideoTimeOutId;
const OnScreenDragableVideo = () => {
  // Website path url
  const { pathname } = useLocation();
  const { storeStates, setStoreStates } = useGlobalStore();
  const { tempCart } = useCartContext();
  const { homeInitialData } = useHomePageContext();

  const [states, setStates] = useState({
    loading: false,
    expandedView: false,
  });

  const pageData = useMemo(() => {
    if (pathname === "/")
      return {
        page: "home",
        initialPosition: "b-l",
        bottomPadding: tempCart && window.innerWidth < 700 ? 70 : 10,
        rightPadding: 4,
        leftPadding: 10,
        topPadding: 4,
        video: storeStates.OSPVideos?.find(
          (video) => video.options.page === "home"
        ),
      };

    if (pathname?.includes("/decors/list"))
      return {
        page: "decorListing",
        initialPosition: "b-l",
        bottomPadding: 85,
        rightPadding: 4,
        leftPadding: 4,
        topPadding: 4,
        video: storeStates.OSPVideos?.find(
          (video) => video.options.page === "decorListing"
        ),
      };

    if (pathname?.includes("/products/decor/"))
      return {
        page: "decorDetails",
        initialPosition: "b-l",
        bottomPadding: 85,
        rightPadding: 4,
        leftPadding: 4,
        topPadding: 4,
        video: storeStates.OSPVideos?.find(
          (video) => video.options.page === "decorDetails"
        ),
      };

    return null;
  }, [pathname, storeStates?.OSPVideos, tempCart]);

  useEffect(() => {
    if (
      homeInitialData.initialData !== null &&
      storeStates.OSPVideos === null
    ) {
      if (getVideoTimeOutId) clearInterval(getVideoTimeOutId);

      getVideoTimeOutId = setTimeout(() => {
        fetchVideoDetails();
      }, 7000);
    }

    return () => {
      if (getVideoTimeOutId) clearInterval(getVideoTimeOutId);
    };
    // eslint-disable-next-line
  }, [homeInitialData.initialData]);

  const fetchVideoDetails = useCallback(async () => {
    setStates((prev) => ({ ...prev, loading: true }));
    try {
      const res = await v2Api({
        operation: "getOSPVideoData",
        site: "eboSite",
        tags: ["home"],
      });

      if (res.isSuccess) {
        setStoreStates((prev) => ({ ...prev, OSPVideos: res.data }));
      }
    } catch (error) {
      console.error("Error fetching video details", error);
    }
    setStates((prev) => ({ ...prev, loading: false }));
  }, [setStoreStates, setStates]);

  const handleVideoClick = async () => {
    const today = new Date().toDateString();
    const clickedDate = localStorage.getItem(`OSP_d`);

    if (clickedDate === today) {
      return; // User has already clicked today
    }

    localStorage.setItem(`OSP_d`, today);

    try {
      await v2Api({
        operation: "handleVideoClicks",
        tags: ["home"],
        props: { tags: ["OSPVideo"] },
      });
    } catch (error) {
      console.error("Error updating click count", error);
    }
  };

  const onCoverVideoClick = () => {
    handleVideoClick();
    setStates((prev) => ({ ...prev, expandedView: true }));
  };

  const handleCloseCoverVideo = () => {
    setStoreStates((p) => ({
      ...p,
      closedOSPVideoPages: [...p.closedOSPVideoPages, pageData.page],
    }));
  };

  if (states.loading || !pageData?.video) return;

  return (
    <DragableContainer
      {...{
        onCoverVideoClick,
        handleCloseCoverVideo,
        states,
        setStates,
        pageData,
        storeStates,
      }}
    />
  );
};

const DragableContainer = ({
  onCoverVideoClick,
  handleCloseCoverVideo,
  pageData,
  states,
  setStates,
  storeStates,
}) => {
  const {
    bottomPadding,
    rightPadding,
    leftPadding,
    topPadding,
    initialPosition,
  } = pageData;

  // container height using the aspect ratio and width
  const containerHeight =
    COVER_VIDEO_WIDHT *
    (parseInt(VIDEO_ASPECT_RATIO.split("/")?.[1]) /
      parseInt(VIDEO_ASPECT_RATIO.split("/")?.[0]));

  // Set initial position based on the initialPosition prop
  const getInitialPosition = () => {
    const { innerWidth: width, innerHeight: height } = window;

    switch (initialPosition) {
      case "t-l": // top-left
        return { x: leftPadding, y: topPadding };
      case "t-r": // top-right
        return { x: width - COVER_VIDEO_WIDHT - rightPadding, y: topPadding };
      case "b-l": // bottom-left
        return { x: leftPadding, y: height - containerHeight - bottomPadding };
      case "b-r": // bottom-right
      default:
        return {
          x: width - COVER_VIDEO_WIDHT - rightPadding,
          y: height - containerHeight - bottomPadding,
        };
    }
  };

  // eslint-disable-next-line
  const [position, setPosition] = useState(getInitialPosition());

  const coverVideo = useMemo(() => {
    if (pageData?.video?.collapsedVideoUrl) {
      return pageData?.video?.collapsedVideoUrl;
    } else {
      return pageData?.video?.videoUrl;
    }
  }, [pageData?.video?.videoUrl, pageData?.video?.collapsedVideoUrl]);

  return (
    <>
      {states.expandedView ? (
        <ExpandedView
          video={pageData?.video}
          handleClose={() => {
            handleCloseCoverVideo();
            setStates((prev) => ({ ...prev, expandedView: false }));
          }}
        />
      ) : (
        <AnimatePresence>
          {!storeStates?.closedOSPVideoPages?.includes?.(pageData.page) && (
            <motion.div
              initial={{ y: 100, x: 0, opacity: 0 }}
              animate={{ y: 0, x: 0, opacity: 1 }}
              exit={{ y: 100, x: 0, opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{
                width: COVER_VIDEO_WIDHT,
                height: containerHeight,
                position: "fixed",
                left: position.x,
                top: position.y,
              }}
              className="z-[50]"
            >
              <CoverVideo
                {...{
                  onVideoClick: onCoverVideoClick,
                  handleClose: handleCloseCoverVideo,
                  url: coverVideo,
                  width: COVER_VIDEO_WIDHT,
                  aspectRatio: VIDEO_ASPECT_RATIO,
                  previewDurationOnCollapsed: pageData?.video?.collapsedVideoUrl
                    ? 15
                    : 4,
                }}
              />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

function ExpandedView({ video, handleClose }) {
  const videoUrl = video.videoUrl;
  const videoRef = useRef(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        videoRef.current.play().catch(() => {
          console.log("Playback interrupted");
        });
      } else {
        videoRef.current.pause();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    // Function to update progress
    const updateProgress = () => {
      if (video && video.duration) {
        setVideoStates((prev) => ({
          ...prev,
          progress: (video.currentTime / video.duration) * 100,
        }));
      }
    };

    // Attach event listener
    if (video) {
      video.addEventListener("timeupdate", updateProgress);
    }

    // Cleanup
    return () => {
      if (video) {
        video.removeEventListener("timeupdate", updateProgress);
      }
    };
  }, []);

  const [videoStates, setVideoStates] = useState({
    muted: false,
    videoLoaded: true,
    progress: 0,
  });
  const windowWidth = window.innerWidth;

  const isSmallScreen = windowWidth < 500;

  const onExpandedWidth = windowWidth > 500 ? 500 : windowWidth;

  return (
    <div className={` fix-screen z-[99] !m-0 flex-center `}>
      <div
        onClick={handleClose}
        className=" absolute bg-[#000000b1] top-0 right-0 left-0 bottom-0 z-[1] "
      ></div>

      <div
        className={`z-[2] relative`}
        style={{
          width: `${onExpandedWidth}px`,
          maxWidth: windowWidth,
          maxHeight: "100dvh",
          height: isSmallScreen ? "100dvh" : `auto`,
          aspectRatio: VIDEO_ASPECT_RATIO,
        }}
      >
        {/* Progress Bar */}
        <div
          style={{
            position: "absolute",
            top: "1.6rem",
            left: "3.5rem",
            right: "3.5rem",
            zIndex: 3,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: `${videoStates.progress}%`,
              height: "4px", // Height of the progress bar
              backgroundColor: "white",
              transition: "width .4s normal",
            }}
            className=" rounded-full z-[2]"
          ></div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: `100%`,
              height: "4px",
              backgroundColor: "white",
            }}
            className=" rounded-full z-[1] opacity-50"
          ></div>
        </div>
        <div
          onClick={() => {
            setVideoStates({
              ...videoStates,
              muted: !videoStates.muted,
            });
          }}
          className="z-[4] cursor-pointer text-lg absolute right-4 top-4  text-white"
        >
          {videoStates.muted ? <GoMute /> : <GoUnmute />}
        </div>

        <div
          onClick={handleClose}
          className="z-[4] cursor-pointer text-lg absolute top-4 left-4   text-white"
        >
          <RxCross2 />
        </div>

        {!videoStates.videoLoaded && (
          <div className="bg-black absolute inset-0 z-[3] rounded-lg flex-center">
            <div
              className="loader w-[1.25rem]"
              style={{
                borderTopColor: "white",
              }}
            ></div>
          </div>
        )}

        <video
          src={videoUrl}
          autoPlay
          ref={videoRef}
          loop
          muted={videoStates.muted}
          playsInline={true}
          controls={false}
          controlsList="nodownload nofullscreen noremoteplayback"
          style={{
            objectFit: "fill",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "100%",
          }}
          className={`z-[1] `}
          onLoadStart={() => {
            setVideoStates((prev) => ({ ...prev, videoLoaded: false }));
          }}
          onLoadedData={() => {
            setVideoStates((prev) => ({ ...prev, videoLoaded: true }));
          }}
        />
      </div>
    </div>
  );
}

function CoverVideo({
  handleClose,
  previewDurationOnCollapsed,
  width, // in px
  aspectRatio,
  onVideoClick = () => {},
  url = "",
}) {
  const videoRef = useRef(null);

  const windowWidth = window.innerWidth;
  const [videoStates, setVideoStates] = useState({
    muted: true,
    videoLoaded: true,
  });

  useEffect(() => {
    const video = videoRef.current;

    const handleTimeUpdate = () => {
      // Loop video from the beginning if it exceeds preview duration and is not expanded
      if (video.currentTime >= previewDurationOnCollapsed) {
        video.currentTime = 0;
        video.play();
      }
    };

    video.addEventListener("timeupdate", handleTimeUpdate);
    return () => video.removeEventListener("timeupdate", handleTimeUpdate);
  }, [previewDurationOnCollapsed]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        videoRef.current.play().catch(() => {
          console.log("Playback interrupted");
        });
      } else {
        videoRef.current.pause();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <div
      className={`  relative   flex-center `}
      style={{
        aspectRatio: aspectRatio,
        width: `${width}px`,
      }}
    >
      <div
        className={`z-[1] relative`}
        style={{
          width: `${width}px`,
          maxWidth: windowWidth,
          maxHeight: "100dvh",
          aspectRatio,
        }}
      >
        <div
          onClick={() => {
            setVideoStates({
              ...videoStates,
              muted: !videoStates.muted,
            });
          }}
          className="z-[4] cursor-pointer text-base absolute left-2 top-2  text-white"
        >
          {videoStates.muted ? <GoMute /> : <GoUnmute />}
        </div>

        <div
          onClick={handleClose}
          className="z-[4] cursor-pointer text-lg absolute top-2 right-2   text-white"
        >
          <RxCross2 />
        </div>
        <div
          onClick={onVideoClick}
          className="z-[4] cursor-pointer text-lg absolute bottom-0 right-2   text-white"
        >
          <AiOutlineExpandAlt />
        </div>

        {!videoStates.videoLoaded && (
          <div className="bg-black absolute inset-0 z-[3] rounded-lg flex-center">
            <div
              className="loader w-[1.25rem]"
              style={{
                borderTopColor: "white",
              }}
            ></div>
          </div>
        )}

        <video
          src={url}
          ref={videoRef}
          onClick={onVideoClick}
          autoPlay
          loop
          muted={videoStates.muted}
          playsInline={true}
          controls={false}
          controlsList="nodownload nofullscreen noremoteplayback"
          style={{
            objectFit: "fill",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "100%",
          }}
          onLoadStart={() => {
            setVideoStates((prev) => ({ ...prev, videoLoaded: false }));
          }}
          onLoadedData={() => {
            setVideoStates((prev) => ({ ...prev, videoLoaded: true }));
          }}
          className={` border border-gray-200 rounded-lg bg-white  z-[1] `}
        />
      </div>
    </div>
  );
}

export default memo(OnScreenDragableVideo);
